import React from 'react';

function ContractList({ contracts, onSelectContract, onDeleteContract }) {
  const truncateAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-bold text-gray-900 mb-4">Deployed Contracts</h2>
      {contracts.length === 0 ? (
        <p className="text-gray-500">No contracts deployed yet.</p>
      ) : (
        <ul className="space-y-4">
          {contracts.map((contract, index) => (
            <li key={index} className="bg-gray-50 p-4 rounded-lg shadow-sm">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <div className="mb-2 sm:mb-0">
                  <button
                    onClick={() => onSelectContract(contract)}
                    className="text-blue-600 hover:text-blue-800 font-medium break-all"
                    title={contract.address}
                  >
                    {truncateAddress(contract.address)}
                  </button>
                  {contract.token_name && (
                    <span className="ml-2 text-sm text-gray-500">({contract.token_name})</span>
                  )}
                </div>
                <button
                  onClick={() => onDeleteContract(contract.address)}
                  className="text-red-500 hover:text-red-700 text-sm font-medium mt-2 sm:mt-0"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ContractList;