import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { Button, Card, Alert, Spinner } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;

function Payment() {
  const [currentStep, setCurrentStep] = useState(0);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentAddresses, setPaymentAddresses] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [paymentAddress, setPaymentAddress] = useState('');
  const [remainingTime, setRemainingTime] = useState(900); // 15 minutes
  const [paymentStatus, setPaymentStatus] = useState('pending');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const navigate = useNavigate();

  const fetchPaymentPlans = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/payments/plans`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setPlans(response.data);
    } catch (error) {
      console.error('Error fetching payment plans:', error);
      setError('Failed to fetch payment plans. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPaymentAddresses = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/payment-addresses`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setPaymentAddresses(response.data);
      if (response.data.length > 0) {
        setSelectedNetwork(response.data[0].network);
        setSelectedCurrency(response.data[0].currency);
      }
    } catch (error) {
      console.error('Error fetching payment addresses:', error);
      setError('Failed to fetch payment methods. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPaymentPlans();
    fetchPaymentAddresses();
  }, [fetchPaymentPlans, fetchPaymentAddresses]);

  useEffect(() => {
    if (currentStep === 2 && remainingTime > 0) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [currentStep, remainingTime]);

  useEffect(() => {
    if (currentStep === 2 && paymentStatus === 'pending') {
      const checkPaymentStatus = setInterval(async () => {
        try {
          const response = await axios.post(
            `${API_URL}/check-payment-status`,
            { paymentAddress },
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
          );
          if (response.data.status === 'completed') {
            setPaymentStatus('completed');
            clearInterval(checkPaymentStatus);

            setTimeout(() => {
                window.location.href = "/";
              }, 10000); // Redirect after 10 seconds
          }
        } catch (error) {
          console.error('Error checking payment status:', error);
        }
      }, 10000); // Check every 10 seconds

      return () => clearInterval(checkPaymentStatus);
    }
  }, [currentStep, paymentAddress, paymentStatus]);

  const handlePlanSelect = (planId) => {
    setSelectedPlan(planId);
  };

  const handleNetworkSelect = (event) => {
    setSelectedNetwork(event.target.value);
    setSelectedCurrency('');
  };

  const handleCurrencySelect = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const initiatePayment = async () => {
    try {
      setLoading(true);
      const selectedPlanAmount = plans.find(p => p.id === selectedPlan)?.price;
      const response = await axios.post(`${API_URL}/initiate-payment`, {
        planId: selectedPlan,
        network: selectedNetwork,
        currency: selectedCurrency,
        amount: selectedPlanAmount // Seçilen planın miktarını da gönderiyoruz
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setPaymentAddress(response.data.paymentAddress);
      setRemainingTime(900); // Reset timer to 15 minutes
      setCurrentStep(2);
    } catch (error) {
      console.error('Error initiating payment:', error);
      setError('Failed to initiate payment. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {plans.map((plan) => (
              <div
                key={plan.id}
                className={`p-4 border rounded cursor-pointer ${selectedPlan === plan.id ? 'border-blue-500 bg-blue-50' : ''}`}
                onClick={() => handlePlanSelect(plan.id)}
              >
                <h3 className="font-bold">{plan.months} Month{plan.months > 1 ? 's' : ''}</h3>
                <p>${plan.price} USDT</p>
              </div>
            ))}
          </div>
        );
      case 1:
        return (
          <div>
            <select
              value={selectedNetwork}
              onChange={handleNetworkSelect}
              className="mb-4 w-full p-2 border rounded"
            >
              <option value="">Select network</option>
              {[...new Set(paymentAddresses.map(a => a.network))].map(network => (
                <option key={network} value={network}>{network}</option>
              ))}
            </select>
            <select
              value={selectedCurrency}
              onChange={handleCurrencySelect}
              className="w-full p-2 border rounded"
              disabled={!selectedNetwork}
            >
              <option value="">Select currency</option>
              {paymentAddresses
                .filter(a => a.network === selectedNetwork)
                .map(a => (
                  <option key={a.currency} value={a.currency}>{a.currency}</option>
                ))}
            </select>
          </div>
        );
      case 2:
        return (
          <div className="text-center">
            <QRCode value={`${paymentAddress}`} size={200} className="mx-auto mb-4" />
            <p className="mb-2"><strong>Address:</strong> {paymentAddress}</p>
            <p className="mb-2"><strong>Amount:</strong> {plans.find(p => p.id === selectedPlan)?.price} {selectedCurrency}</p>
            <p className="mb-2"><strong>Network:</strong> {selectedNetwork}</p>
            <p className="mb-4"><strong>Time Remaining:</strong> {formatTime(remainingTime)}</p>
            {paymentStatus === 'pending' ? (
              <div>
                <Spinner animation="border" role="status" />
                <p>Waiting for payment confirmation... This may take a few minutes.</p>
              </div>
            ) : (
              <Alert variant="success">Payment completed successfully!</Alert>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 mt-4">{error}</div>;
  }

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Subscription Payment</h2>
      <div className="flex justify-between mb-8">
        <div className={`flex-1 text-center ${currentStep >= 0 ? 'font-bold' : ''}`}>Select Plan</div>
        <div className={`flex-1 text-center ${currentStep >= 1 ? 'font-bold' : ''}`}>Payment Method</div>
        <div className={`flex-1 text-center ${currentStep >= 2 ? 'font-bold' : ''}`}>Make Payment</div>
      </div>
      <div className="mb-8">
        {renderStep()}
      </div>
      <div className="flex justify-between">
        {currentStep > 0 && (
          <button 
            onClick={() => setCurrentStep(currentStep - 1)}
            disabled={currentStep === 2}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded"
          >
            Previous
          </button>
        )}
        {currentStep < 2 && (
          <button 
            onClick={() => currentStep === 1 ? initiatePayment() : setCurrentStep(currentStep + 1)}
            disabled={
              (currentStep === 0 && !selectedPlan) ||
              (currentStep === 1 && (!selectedNetwork || !selectedCurrency))
            }
            className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
          >
            {currentStep === 1 ? 'Proceed to Payment' : 'Next'}
          </button>
        )}
      </div>
    </div>
  );
}

export default Payment;
