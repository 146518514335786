// src/components/ContractInteraction.js
import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import AirdropForm from './AirdropForm';  // AirdropForm'u ayrı bir dosyaya taşıdıysanız


function replacer(key, value) {
  if (typeof value === 'bigint') {
    return value.toString();
  }
  return value;
}

function FunctionInputs({ func, onInteract, result, isAdmin, isOwner }) {
  const [inputs, setInputs] = useState(func.inputs.map(() => ''));
  const [isOpen, setIsOpen] = useState(false);
  const [bnbAmount, setBnbAmount] = useState('');

  const canInteract = isAdmin || isOwner;

  const handleInputChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);
  };

  const isViewFunction = func.stateMutability === 'view' || func.stateMutability === 'pure';

  const handleInteract = () => {
    if (!isViewFunction && !canInteract) {
      alert("Bu işlemi gerçekleştirmek için admin veya sözleşme sahibi olmalısınız.");
      return;
    }
    if (func.name === 'addLiquidity') {
      onInteract(func.name, [...inputs, bnbAmount], isViewFunction);
    } else {
      onInteract(func.name, inputs, isViewFunction);
    }
  };

  // Hide _Realadmin function if user is not an admin
  if (func.name === '_Realadmin' && !isAdmin) {
    return null;
  }

  if (func.name === 'airdrop') {
    return null;
  }

  const buttonClass = func.name === 'addLiquidity' 
    ? 'mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
    : `mt-2 ${isViewFunction ? 'bg-blue-500 hover:bg-blue-700' : 'bg-green-500 hover:bg-green-700'} text-white font-bold py-2 px-4 rounded`;

  return (
    <div className="mb-4 p-4 border rounded">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-lg font-semibold w-full text-left"
      >
        {func.name}
      </button>
      <CSSTransition
        in={isOpen}
        timeout={100}
        classNames="accordion"
        unmountOnExit
      >
        <div className="mt-2">
          {func.inputs.map((input, index) => (
            <input
              key={index}
              type="text"
              placeholder={`${input.name} (${input.type})`}
              value={inputs[index]}
              onChange={(e) => handleInputChange(index, e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          ))}
          {func.name === 'addLiquidity' && (
            <input
              type="number"
              placeholder="BNB Amount"
              value={bnbAmount}
              onChange={(e) => setBnbAmount(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          )}
          <button
            onClick={handleInteract}
            className={buttonClass}
            disabled={!isViewFunction && !canInteract}
          >
            {isViewFunction ? 'Call' : 'Transact'}
          </button>
          {!isViewFunction && !canInteract && (
            <p className="text-red-500 mt-2">Bu işlemi gerçekleştirmek için admin veya sözleşme sahibi olmalısınız.</p>
          )}
          {result && (
            <div className="mt-2">
              <h4 className="font-semibold">Sonuç:</h4>
              <pre className="bg-gray-100 p-2 rounded">{result}</pre>
            </div>
          )}
        </div>
      </CSSTransition>
    </div>
  );
}

function ContractInteraction({ contractAddress, contractABI, isAdmin }) {
  const { active, library: provider, account } = useWeb3React();
  const [results, setResults] = useState({});
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    checkOwner();
  }, [active, provider, account, contractAddress]);

  async function checkOwner() {
    if (active && provider && account) {
      try {
        const web3 = new Web3(provider.provider);
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        const owner = await contract.methods.owner().call();
        setIsOwner(owner.toLowerCase() === account.toLowerCase());
      } catch (error) {
        console.error('Sahip kontrolü hatası:', error);
        setIsOwner(false);
      }
    } else {
      setIsOwner(false);
    }
  }

  async function interactWithContract(functionName, inputs, isViewFunction) {
    if (!active) {
      alert('Lütfen önce MetaMask\'a bağlanın');
      return;
    }

    if (!isViewFunction && !(isAdmin || isOwner)) {
      alert("Bu işlemi gerçekleştirmek için admin veya sözleşme sahibi olmalısınız.");
      return;
    }

    const web3 = new Web3(provider.provider);
    const accounts = await web3.eth.getAccounts();
    const contract = new web3.eth.Contract(contractABI, contractAddress);

    try {
      let result;
      if (isViewFunction) {
        result = await contract.methods[functionName](...inputs).call({ from: accounts[0] });
      } else {
        let options = { from: accounts[0] };
        if (functionName === 'addLiquidity') {
          options.value = web3.utils.toWei(inputs.pop(), 'ether'); // Convert BNB amount to Wei
        }
        result = await contract.methods[functionName](...inputs).send(options);
      }
      setResults(prevResults => ({
        ...prevResults,
        [functionName]: JSON.stringify(result, replacer, 2)
      }));
    } catch (error) {
      console.error('Etkileşim hatası:', error);
      setResults(prevResults => ({
        ...prevResults,
        [functionName]: 'Hata: ' + error.message
      }));
    }
  }

  const viewFunctions = contractABI.filter(func => (func.type === 'function' && (func.stateMutability === 'view' || func.stateMutability === 'pure')));
  const transactFunctions = contractABI.filter(func => (func.type === 'function' && !(func.stateMutability === 'view' || func.stateMutability === 'pure')));

  return (
    <div className="mt-8">
      <h2 className="text-xl font-bold mb-4">Sözleşme Etkileşimi</h2>
      {!(isOwner) && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
          <p className="font-bold">Uyarı</p>
          <p>Admin veya sözleşme sahibi değilsiniz. Sadece görüntüleme fonksiyonlarını çağırabilirsiniz.</p>
          <p>Diğer fonksiyonları çağırdığınızda işlemleriniz reddedilebilir.</p>
        </div>
      )}
      <AirdropForm 
        contractAddress={contractAddress}
        contractABI={contractABI}
        onInteract={interactWithContract} 
        isAdmin={isAdmin} 
        isOwner={isOwner} 
      />
      <div>
        <h3 className="text-lg font-bold mb-2">Transact Functions</h3>
        {transactFunctions.map(func => (
          <FunctionInputs
            key={func.name}
            func={func}
            onInteract={interactWithContract}
            result={results[func.name]}
            isAdmin={isAdmin}
            isOwner={isOwner}
          />
        ))}
      </div>
      <div className="mt-8">
        <h3 className="text-lg font-bold mb-2">View Functions</h3>
        {viewFunctions.map(func => (
          <FunctionInputs
            key={func.name}
            func={func}
            onInteract={interactWithContract}
            result={results[func.name]}
            isAdmin={isAdmin}
            isOwner={isOwner}
          />
        ))}
      </div>
    </div>
  );
}

export default ContractInteraction;