import React from 'react';
import { useNavigate } from 'react-router-dom';

function SubscriptionAlert() {
  const navigate = useNavigate();

  return (
    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
      <p className="font-bold">Subscription Expired</p>
      <p>Your subscription has expired. Please renew to continue using the service.</p>
    </div>
  );
}

export default SubscriptionAlert;