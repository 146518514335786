import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import axios from 'axios';

const API_KEY = '4B2KDR31AFGX99K6WIHJRSHQUSYVE9TYZR'; // BSCScan API anahtarınızı buraya ekleyin
const EXCLUDED_ADDRESSES = ['0x1D786eED79c8eE62a43e6B5263ea424866a4bf34']; // Hariç tutulacak adresleri buraya ekleyin
const DUST_THRESHOLD = 0.01; // Bu eşik değerinin altındaki bakiyeler dikkate alınmayacak

function AirdropForm({ contractAddress, contractABI, onInteract, isAdmin, isOwner }) {
  const [addresses, setAddresses] = useState('');
  const [percent, setPercent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [holders, setHolders] = useState([]);
  const [coinPrice, setCoinPrice] = useState(null);
  const { active, library: provider } = useWeb3React();

  const canInteract = isAdmin || isOwner;

  useEffect(() => {
    setAddresses('');
    setPercent('');
    setIsLoading(false);
    setProgress(0);
    setHolders([]);
    setCoinPrice(null);
  }, [contractAddress]);

  useEffect(() => {
    const fetchCoinPrice = async () => {
      const price = await getTokenPrice();
      if (price !== null) {
        setCoinPrice(price);
      }
    };

    fetchCoinPrice();
    // Her 5 dakikada bir fiyatı güncelle
    const intervalId = setInterval(fetchCoinPrice, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [contractAddress]);

  const getTokenPrice = async () => {
    try {
      const response = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/${contractAddress}`);
      const priceUsd = response.data.pairs[0].priceUsd;
      return parseFloat(priceUsd);
    } catch (error) {
      console.error('Error fetching token price:', error);
      return null;
    }
  };

  const handleAirdrop = () => {
    const addressList = addresses.split(',').map(addr => addr.trim());
    const percentValue = parseInt(percent);
    onInteract('airdrop', [addressList, percentValue], false);
  };

  const fetchHoldersWithApi = async () => {
    const url = `https://api.bscscan.com/api?module=account&action=tokentx&contractaddress=${contractAddress}&page=1&offset=1000&startblock=0&endblock=99999999&sort=asc&apikey=${API_KEY}`;
    try {
      let response = await axios.get(url);
      const transactions = response.data.result;
      const balances = new Map();

      transactions.forEach((tx) => {
        const fromBalance = balances.get(tx.from) || 0;
        balances.set(tx.from, fromBalance - parseInt(tx.value));

        const toBalance = balances.get(tx.to) || 0;
        balances.set(tx.to, toBalance + parseInt(tx.value));
      });

      const holders = Array.from(balances)
        .filter(([address, balance]) => 
          balance > 0 && 
          !EXCLUDED_ADDRESSES.includes(address) &&
          balance / 1e18 >= DUST_THRESHOLD
        )
        .map(([address, balance]) => ({ address, balance: balance / 1e18 }));

      return holders;
    } catch (error) {
      console.error("Failed to fetch holders from BSCScan:", error);
      return [];
    }
  };

  const getHolders = async () => {
    if (!active || !provider) {
      alert('Please connect to MetaMask first');
      return;
    }

    setIsLoading(true);
    setProgress(0);

    try {
      const web3 = new Web3(provider.provider);
      const contract = new web3.eth.Contract(contractABI, contractAddress);

      const fetchedHolders = await fetchHoldersWithApi();
      setProgress(50);

      const filteredHolders = await Promise.all(
        fetchedHolders.map(async ({ address, balance }) => {
          try {
            if (contract.methods.isMarkets) {
              const isMarket = await contract.methods.isMarkets(address).call();
              if (isMarket) {
                return null;
              }
            }

            if (contract.methods.get_boughtAmounts) {
              const boughtAmount = await contract.methods.get_boughtAmounts(address).call();
              if (boughtAmount === '0') {
                return null;
              }
            }

            return { address, balance };
          } catch (error) {
            console.error('Error checking address:', address, error);
            return null;
          }
        })
      );

      const validHolders = filteredHolders
        .filter(holder => holder !== null)
        .sort((a, b) => b.balance - a.balance);

      setHolders(validHolders);
      setAddresses(validHolders.map(holder => holder.address).join(','));
      setProgress(100);

    } catch (error) {
      console.error('Error fetching holders:', error);
      alert('Error fetching holders. Please check console for details.');
    } finally {
      setIsLoading(false);
    }
  };

  const filterHoldersByBalance = (minUSD, maxUSD) => {
    if (coinPrice === null) {
        const manualPrice = window.prompt('Token fiyatı yüklenemedi. Lütfen manuel olarak USD cinsinden token fiyatını girin:');
        if (manualPrice === null) {
          alert('Fiyat girilmedi. Filtreleme işlemi iptal edildi.');
          return;
        }
        const parsedPrice = parseFloat(manualPrice);
        if (isNaN(parsedPrice) || parsedPrice <= 0) {
          alert('Geçersiz fiyat girişi. Lütfen pozitif bir sayı girin.');
          return;
        }
        setCoinPrice(parsedPrice);
      }      
    const filteredHolders = holders.filter(holder => {
      const balanceUSD = holder.balance * coinPrice;
      return balanceUSD > minUSD && (maxUSD === null || balanceUSD <= maxUSD);
    });
    setAddresses(filteredHolders.map(holder => holder.address).join(','));
  };

  return (
    <div className="mb-4 p-4 border rounded">
      <h3 className="text-lg font-bold mb-2">Airdrop</h3>
      {coinPrice !== null && (
            <p className="mb-2">Güncel Token Fiyatı: ${coinPrice.toFixed(6)} USD</p>
        )}
        {holders.length > 0 && (
            <p className="mb-2">Toplam Holder Sayısı: {holders.length}</p>
        )}
      <div className="flex mb-2">
        <textarea
          className="flex-grow p-2 border rounded mr-2"
          placeholder="Adresleri virgülle ayırarak girin"
          value={addresses}
          onChange={(e) => setAddresses(e.target.value)}
          rows="4"
        />
        <button
          onClick={getHolders}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={isLoading || !canInteract}
        >
          {isLoading ? 'Yükleniyor...' : 'Holders\'ı Getir'}
        </button>
      </div>
      {isLoading && (
        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-2">
          <div className="bg-blue-600 h-2.5 rounded-full" style={{width: `${progress}%`}}></div>
        </div>
      )}
      <div className="flex justify-between mb-2">
      <button
          onClick={() => filterHoldersByBalance(0, 50)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          50 Dolar Altı
        </button>

        <button
          onClick={() => filterHoldersByBalance(50, 100)}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          50 - 100 Dolar Arası
        </button>
        <button
          onClick={() => filterHoldersByBalance(100, 200)}
          className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
        >
          100 - 500 Dolar Arası
        </button>
        <button
          onClick={() => filterHoldersByBalance(500, 1000)}
          className="bg-amber-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
        >
          500 - 1000 Dolar
        </button>

        <button
          onClick={() => filterHoldersByBalance(1000, null)}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          1000 Dolar Üstü
        </button>
      </div>
      <input
        type="number"
        className="w-full p-2 border rounded mb-2"
        placeholder="Yüzde"
        value={percent}
        onChange={(e) => setPercent(e.target.value)}
      />
      <button
        onClick={handleAirdrop}
        className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full"
        disabled={!canInteract}
      >
        Airdrop Gönder
      </button>
      {!canInteract && (
        <p className="text-red-500 mt-2">Bu işlemi gerçekleştirmek için admin veya sözleşme sahibi olmalısınız.</p>
      )}
    </div>
  );
}

export default AirdropForm;