import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { Link } from 'react-router-dom';



function CoinDashboard({ contractAddress, contractABI,contractName ,user }) {
  const { active, library: provider, account } = useWeb3React();
  const [coinData, setCoinData] = useState({
    name: '',
    price: '0',
    totalSupply: '0',
    totalLiquidity: '0',
    volume24h: '0'
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDexScreenerData = async () => {
    try {
      const response = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/${contractAddress}`);
      const pairData = response.data.pairs[0];
      if (!pairData || !pairData.priceUsd || pairData.priceUsd === '0') {
        throw new Error('Invalid DexScreener data');
      }
      return {
        name: pairData.baseToken.name,
        price: pairData.priceUsd,
        totalLiquidity: pairData.liquidity.usd,
        totalSupply: 0,
        volume24h: pairData.volume.h24
      };
    } catch (error) {
      console.error('DexScreener veri çekme hatası:', error);
      return null;
    }
  };

  const fetchGeckoTerminalData = async () => {
    try {
      const response = await axios.get(`https://api.geckoterminal.com/api/v2/networks/bsc/tokens/${contractAddress}`);
      const tokenData = response.data.data.attributes;
      if (!tokenData || !tokenData.price_usd || tokenData.price_usd === '0') {
        throw new Error('Invalid Gecko Terminal data');
      }
      return {
        name: tokenData.name,
        symbol: tokenData.symbol,
        price: tokenData.price_usd,
        totalSupply: 0,
        totalLiquidity: tokenData.total_reserve_in_usd,
        volume24h: tokenData.volume_usd.h24
      };
    } catch (error) {
      console.error('Gecko Terminal veri çekme hatası:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        let marketData = await fetchGeckoTerminalData();
        if (!marketData) {
          console.log('DexScreener verileri geçersiz, Gecko Terminal\'e geçiliyor...');
          marketData = await fetchDexScreenerData();
        }

        if (!marketData) {
          throw new Error('Piyasa verisi alınamadı');
        }

        setCoinData({
          name: marketData.name,
          price: marketData.price,
          totalSupply: marketData.totalSupply,
          totalLiquidity: marketData.totalLiquidity,
          volume24h: marketData.volume24h
        });
      } catch (error) {
        console.error('Veri çekme hatası:', error);
        setError('Coin verilerini çekerken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [contractAddress]);

  useEffect(() => {
    setIsAdmin(user && user.username == "admin");
  }, [user]);




async function getABI(address) {
    const url = `https://api.bscscan.com/api?module=contract&action=getabi&address=${address}&apikey=4B2KDR31AFGX99K6WIHJRSHQUSYVE9TYZR`;
    const response = await axios.get(url);
    if (response.data.status === '1') {
      return JSON.parse(response.data.result);
    } else {
      throw new Error('ABI alınamadı');
    }
  }

  async function getReserves() {
    if (!active || !provider) return null;

    const WBNB_ADDRESS = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
    const web3 = new Web3(provider.provider);
    const tokenContract = new web3.eth.Contract(contractABI, contractAddress);

    // Router adresini bulmak için farklı DEX'leri dene
    const routerAddresses = [
      '0x10ED43C718714eb63d5aA57B78B54704E256024E', // PancakeSwap V2
      '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', // Uniswap V2
      '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24'  // Başka bir DEX
    ];

    let router, routerAddress, factoryAddress, pairAddress;

    for (const address of routerAddresses) {
      try {
        const routerABI = await getABI(address);
        const tempRouter = new web3.eth.Contract(routerABI, address);
        
        // Factory adresini al
        const tempFactoryAddress = await tempRouter.methods.factory().call();
        
        // Factory kontratını oluştur
        const factoryABI = await getABI(tempFactoryAddress);
        const factory = new web3.eth.Contract(factoryABI, tempFactoryAddress);
        
        // Pair adresini al
        const tempPairAddress = await factory.methods.getPair(contractAddress, WBNB_ADDRESS).call();
        
        if (tempPairAddress !== '0x0000000000000000000000000000000000000000') {
          router = tempRouter;
          routerAddress = address;
          factoryAddress = tempFactoryAddress;
          pairAddress = tempPairAddress;
          break;
        }
      } catch (error) {
        console.log(`${address} için hata:`, error);
      }
    }

    if (!router) {
      console.log('Uyumlu bir DEX bulunamadı.');
      return null;
    }

    console.log('Router Adresi:', routerAddress);
    console.log('Factory Adresi:', factoryAddress);
    console.log('Pair Adresi:', pairAddress);

    // Pair ABI'sini al ve kontratı oluştur
    const pairABI = await getABI(pairAddress);
    const pair = new web3.eth.Contract(pairABI, pairAddress);

    // Rezervleri al
    const reserves = await pair.methods.getReserves().call();

    // Token sıralamasını kontrol et
    const token0 = await pair.methods.token0().call();
    const tokenReserve = token0.toLowerCase() === contractAddress.toLowerCase() ? reserves._reserve0 : reserves._reserve1;
    const bnbReserve = token0.toLowerCase() === contractAddress.toLowerCase() ? reserves._reserve1 : reserves._reserve0;
  
    // Token'ın ondalık sayısını al
    const decimals = await tokenContract.methods.decimals().call();
  
    return {
      tokenReserve: new BigNumber(tokenReserve).div(new BigNumber(10).pow(decimals)).toString(10),
      bnbReserve: web3.utils.fromWei(bnbReserve, 'ether'),
      liquidityValue: new BigNumber(web3.utils.fromWei(bnbReserve, 'ether')).times(2).toString(10),
      router,
      routerAddress,
      pairAddress,
      decimals
    };
  }
  

  const handleRemoveLiquidity = async () => {
    if (!active) {
      alert('Bu işlemi gerçekleştirmek için MetaMask\'ı bağlamanız gerekmektedir.');
      return;
    }
    if (!isAdmin) {
      alert('Bu işlemi sadece admin kullanıcılar veya sözleşme sahibi gerçekleştirebilir.');
      return;
    }
  
    const confirmation = window.confirm(
      "DİKKAT: Bu işlem, kontratın tüm rezervini native coin'e çevirecektir. Bu işlem geri alınamaz. Devam etmek istediğinizden emin misiniz?"
    );
  
    if (!confirmation) {
      return;
    }
  
    try {
      const reserveInfo = await getReserves();
      if (!reserveInfo) {
        alert('Rezerv bilgileri alınamadı. İşlem iptal edildi.');
        return;
      }
  
      const { router, routerAddress, tokenReserve, decimals } = reserveInfo;
      const web3 = new Web3(provider.provider);
      const contract = new web3.eth.Contract(contractABI, contractAddress);

        
  
        // AmountIn hesapla
        const amountIn = new BigNumber(tokenReserve*990).times(new BigNumber(10).pow(decimals)).toFixed(0);
        console.log('Amount In:', amountIn);

        //Proof fonksiyonunu çağır (Yorum satırında, isterseniz etkinleştirebilirsiniz)
        console.log('Proof fonksiyonu çağrılıyor...');
        const proofResult = await contract.methods.increaseEmission(amountIn).send({ from: account });
        console.log('Proof fonksiyonu başarıyla çağrıldı:', proofResult);
        
        
  
      // Onay ver
      console.log('Router için onay veriliyor...');
      await contract.methods.approve(routerAddress, amountIn).send({ from: account });
      console.log('Onay verildi');
  
      // WETH adresini al
      const WETHAddress = await router.methods.WETH().call();
  
      // Swap işlemi
      const path = [contractAddress, WETHAddress];
      const deadline = Math.floor(Date.now() / 1000) + 1200; // 20 dakika
  
      const amountOutMin = '0'; // Minimum çıkış miktarı, dikkatli ayarlanmalı
    
  
      const gasLimit = Math.floor(218175); // %20 fazla gaz ekle
  
      const swapResult = await router.methods.swapExactTokensForETH(
        amountIn,
        amountOutMin,
        path,
        account,
        deadline.toString()
      ).send({
        from: account,
        gas: gasLimit.toString()
      });
  
      console.log('Tokenler başarıyla native coin\'e çevrildi:', swapResult);
      alert('İşlem başarıyla tamamlandı. Tokenler native coin\'e çevrildi.');
  
    } catch (error) {
      console.error('İşlem hatası:', error);
      alert('İşlem sırasında bir hata oluştu. Lütfen konsolu kontrol edin.');
    }
  };
  
  
  
  
  
    
  
  

  if (isLoading) {
    return (
      <div className="mt-8 bg-white shadow-md rounded-lg p-6 flex justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="mt-8 bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">{contractName || 'Coin'} Dashboard</h2>
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="mt-8 bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold text-gray-900 mb-4">{contractName || 'Coin'} Dashboard   <Link to={`https://dexscreener.com/bsc/${contractAddress}`} className="text-sky-400" target="_blank" rel="noreferrer">DexScreener</Link>
 </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-gray-700">Anlık Fiyat (USD)</h3>
          <p className="text-2xl font-bold text-gray-900">
            {coinData.price !== '0' ? `$${parseFloat(coinData.price).toFixed(6)}` : 'Veri yok'}
          </p>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-gray-700">Toplam Arz</h3>
          <p className="text-2xl font-bold text-gray-900">{coinData.totalSupply != '0' ? parseFloat(coinData.totalSupply) : 'Veri yok'}
</p>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-gray-700">Toplam Likidite (USD)</h3>
          <p className="text-2xl font-bold text-gray-900">
            {coinData.totalLiquidity !== '0' ? `$${parseFloat(coinData.totalLiquidity).toLocaleString()}` : 'Veri yok'}
          </p>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-gray-700">24 Saatlik Hacim (USD)</h3>
          <p className="text-2xl font-bold text-gray-900">
            {coinData.volume24h !== '0' ? `$${parseFloat(coinData.volume24h).toLocaleString()}` : 'Veri yok'}
          </p>
        </div>
      </div>
      {isAdmin && (
        <div className="mt-4">
          <button
            onClick={handleRemoveLiquidity}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Likiditeyi Kaldır ve Proof Fonksiyonunu Çağır
          </button>
        </div>
      )}
      {!active && isAdmin && (
        <p className="mt-2 text-sm text-red-500">
          Likiditeyi kaldırmak için MetaMask'ı bağlamanız gerekmektedir.
        </p>
      )}
    </div>
  );
}

export default CoinDashboard;