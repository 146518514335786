import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Web3ReactProvider, useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { ethers } from 'ethers';
import axios from 'axios';
import ConnectWallet from './components/ConnectWallet'; 
import ContractDeployer from './components/ContractDeployer'; 
import ContractInteraction from './components/ContractInteraction'; 
import ContractList from './components/ContractList'; 
import Auth from './components/Auth'; 
import CoinDashboard from './components/CoinDashboard'; 
import AdminPanel from './components/AdminPanel'; 
import Header from './components/Header'; 
import Payment from './components/Payment'; 
import Profile from './components/Profile'; 
import contractABI from './contractABI.json'; 
import SubscriptionAlert from './components/SubscriptionAlert';
import Register from './components/Register';
import ProxyAlert from './components/ProxyAlert';
import AdminDashboard from './components/AdminDashboard';




const API_URL = process.env.REACT_APP_API_URL;

function getLibrary(provider) {
  return new Web3Provider(provider);
}

function App() {
  const { library, active } = useWeb3React();
  const [deployedContracts, setDeployedContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null);
  const [newContractAddress, setNewContractAddress] = useState('');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isProxyDetected, setIsProxyDetected] = useState(false);


  const checkProxyStatus = useCallback(async () => {
    
    try {
      const response = await axios.get(`${API_URL}/get-status`);
      if (response.status != 200) {
        setIsProxyDetected(true);
      }
    } catch (error) {
        setIsProxyDetected(true);
    }
  }, []);

  useEffect(() => {
    checkProxyStatus();
  }, [checkProxyStatus]);

  const getTokenName = async (address, abi) => {
    try {
      let provider;
      if (active && library) {
        provider = library.getSigner();
      } else {
        provider = new ethers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
      }
      const contract = new ethers.Contract(address, abi, provider);
      const name = await contract.name();
      return name;
    } catch (error) {
      console.error('Error fetching token name:', error);
      return '';
    }
  };


  const fetchDeployedContracts = useCallback(async (token) => {
    try {
      const response = await axios.get(`${API_URL}/contracts`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDeployedContracts(response.data);
    } catch (error) {
      console.error('Error fetching deployed contracts:', error);
    }
  }, []);

  const fetchUserInfo = useCallback(async (token) => {
    try {
      const response = await axios.get(`${API_URL}/user-info`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser({ ...response.data, token, isAdmin: response.data.isAdmin }); // isAdmin'i ekledik
      fetchDeployedContracts(token);
    } catch (error) {
      console.error('Error fetching user info:', error);
      setUser(null);
      localStorage.removeItem('token');
    } finally {
      setLoading(false);
    }
  }, [fetchDeployedContracts]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserInfo(token);
    } else {
      setLoading(false);
    }
  }, [fetchUserInfo]);

  

  const handleDeploy = (address, abi) => {
    addContract(address, abi);
  };

  const addContract = async (address, abi = contractABI) => {
    // Önce yerel kontrol
    if (deployedContracts.some(contract => contract.address.toLowerCase() === address.toLowerCase())) {
      alert('Bu kontrat zaten listenizde mevcut.');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      
      // Kontratın enable durumunu kontrol et
      const enableResponse = await axios.put(`${API_URL}/contracts/${address}/enable`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (enableResponse.data.success) {
        // Kontrat zaten var ve enable edildi
        const token_name = await getTokenName(address, abi);
        const newContract = { address, abi, token_name };
        const updatedContracts = [...deployedContracts, newContract];
        setDeployedContracts(updatedContracts);
        localStorage.setItem('deployedContracts', JSON.stringify(updatedContracts));
        setNewContractAddress('');
      } 
      if (!enableResponse.data.contractExists) {
        // Kontrat yok veya enable edilemedi, yeni eklememiz gerekiyor
        
        // BSC API'den token adını çek
        const token_name = await getTokenName(address, abi);
        if (!token_name) {
          token_name = "Unkown";
        }
  
        // Yeni kontratı ekle
        const response = await axios.post(`${API_URL}/contracts`, { 
          address,
          token_name: token_name
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        if (response.data && response.data.success) {
          const newContract = { address, abi, token_name };
          setDeployedContracts(prevContracts => [...prevContracts, newContract]);
          alert('Yeni kontrat başarıyla eklendi.');
        } else {
          throw new Error('Kontrat eklenirken bir hata oluştu.');
        }
      }
  
      setNewContractAddress('');
    } catch (error) {
      console.error('Kontrat eklenirken hata oluştu:', error);
      alert('Kontrat eklenirken hata oluştu: ' + error.message);
    }
  };
  
  

  const handleSelectContract = (contract) => {
    setSelectedContract({ ...contract, abi: contractABI });
  };

  const handleDeleteContract = async (addressToDelete) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/contracts/${addressToDelete}/disable`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });

      fetchDeployedContracts(token); // Refresh the list after disabling
      
      if (selectedContract && selectedContract.address === addressToDelete) {
        setSelectedContract(null);
      }
    } catch (error) {
      console.error('Error disabling contract:', error);
      alert('Error disabling contract: ' + error.message);
    }
  };

  const handleLogin = async (token) => {
    localStorage.setItem('token', token);
    fetchUserInfo(token);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setDeployedContracts([]);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isProxyDetected) {
    return <ProxyAlert />;
  }

  const isSubscriptionValid = user && (user.daysUntilExpiration > 0 || user.isTrialActive || user.username === "admin");

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Router>
        <div className="min-h-screen bg-gray-100">
          <Header 
            user={user} 
            onLogout={handleLogout} 
            isTrialActive={user?.isTrialActive}
            daysLeftInTrial={user?.daysLeftInTrial}
            daysUntilExpiration={user?.daysUntilExpiration}
          />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {user && !isSubscriptionValid && <SubscriptionAlert />}
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route
                path="/auth"
                element={user ? <Navigate to="/" replace /> : <Auth onLogin={handleLogin} />}
              />
              
              <Route
                path="/"
                element={
                  user ? (
                    isSubscriptionValid ? (
                      <>
                        <div className="bg-white shadow-md rounded-lg p-6 mb-8 text-center">
                          <ConnectWallet />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                          <div className="bg-white shadow-md rounded-lg p-6">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">Deploy New Contract</h2>
                            <ContractDeployer onDeploy={handleDeploy} user={user} />
                          </div>
                          <div className="bg-white shadow-md rounded-lg p-6">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">Add Existing Contract</h2>
                            <div className="flex space-x-2">
                              <input
                                type="text"
                                value={newContractAddress}
                                onChange={(e) => setNewContractAddress(e.target.value)}
                                placeholder="Contract Address"
                                className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                              />
                              <button
                                onClick={() => addContract(newContractAddress)}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mt-8 bg-white shadow-md rounded-lg p-6">
                          <ContractList 
                            contracts={deployedContracts} 
                            onSelectContract={handleSelectContract}
                            onDeleteContract={handleDeleteContract}
                          />
                        </div>
                        {selectedContract && (
                          <>
                            <div className="mt-8 bg-white shadow-md rounded-lg p-6">
                              <CoinDashboard 
                                contractAddress={selectedContract.address} 
                                contractABI={selectedContract.abi}
                                contractName={selectedContract.token_name} 
                                user={user}
                              />
                            </div>
                            <div className="mt-8 bg-white shadow-md rounded-lg p-6">
                              <h2 className="text-2xl font-bold text-gray-900 mb-4">Contract Interaction</h2>
                              <ContractInteraction 
                                contractAddress={selectedContract.address} 
                                contractABI={selectedContract.abi}
                                isAdmin={user.isAdmin === 1}
                              />
                            </div>
                          </>
                        )}
                        {/* {user.username === "admin" && <AdminPanel user={user} />} */}
                      </>
                    ) : (
                      <Navigate to="/payment" replace />
                    )
                  ) : (
                    <Navigate to="/auth" replace />
                  )
                }
              />
              <Route
                path="/payment"
                element={user ? <Payment /> : <Navigate to="/auth" replace />}
              />
              <Route
                path="/profile"
                element={user ? <Profile /> : <Navigate to="/auth" replace />}
              />

            <Route
                path="/admin-dashboard"
                element={
                  user && user.isAdmin ? (
                    <AdminDashboard user={user} />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </div>
      </Router>
    </Web3ReactProvider>
  );
}

export default App;