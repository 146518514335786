import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import contractABI from '../contractABI.json';


const routerAddresses = {
  'BSC-Uniswap': '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24',
  'BSC-PancakeSwap': '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  'BSC-SushiSwap': '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506'
};

function ContractDeployer({ onDeploy, user }) {
  const { active, library: provider, account } = useWeb3React();
  const [deployedAddress, setDeployedAddress] = useState('');
  const [routerAddress, setRouterAddress] = useState('');
  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');

  const setRouter = (router) => {
    setRouterAddress(routerAddresses[router]);
  };

  async function deployContract() {
    if (!user) {
      alert('User information is missing. Please log in again.');
      return;
    }

    if (!active) {
      alert('Please connect to MetaMask first');
      return;
    }

    if (!routerAddress || !tokenName || !tokenSymbol) {
      alert('Please fill all fields');
      return;
    }

    try {
      // Fetch contractBytecode only when deploying
      const response = await fetch('/contractBytecode.bin');
      const contractBytecode = await response.text();

      if (!contractBytecode.trim()) {
        throw new Error('Contract bytecode is missing or invalid');
      }

      const web3 = new Web3(provider.provider);
      const contract = new web3.eth.Contract(contractABI);
      const currentUser = localStorage.getItem('user');
      console.log('Deploying contract with arguments:', [routerAddress, tokenName, tokenSymbol]);
      console.log('Bytecode length:', contractBytecode.trim().length);


      const deployedContract = await contract
        .deploy({
          data: contractBytecode.trim(),
          arguments: [routerAddress, tokenName, tokenSymbol]
        })
        .send({ from: account });

      const newAddress = deployedContract.options.address;
      setDeployedAddress(newAddress);

      // Save the deployed contract to the database
      const Authtoken = localStorage.getItem('token');
      const saveResponse = await fetch(`${process.env.REACT_APP_API_URL}/contracts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Authtoken}`
        },
        body: JSON.stringify({
          address: newAddress,
          token_name: tokenName,
          user_id: user.id // Assuming the user's Ethereum address is their user_id
        })
      });

      if (!saveResponse.ok) {
        throw new Error('Failed to save contract to database');
      }

      const result = await saveResponse.json();
      console.log('Contract saved to database:', result);

      onDeploy(newAddress, contractABI);
    } catch (error) {
      console.error('Deployment error:', error);
      alert('Error deploying contract: ' + error.message);
    }
  }

  return (
    <div className="mt-8">
      <div className="mb-2">
        <input
          type="text"
          value={routerAddress}
          onChange={(e) => setRouterAddress(e.target.value)}
          placeholder="Router Address"
          className="w-full px-3 py-2 border rounded-md"
        />
        <div className="flex mt-2 space-x-2">
          {Object.keys(routerAddresses).map((router) => (
            <button
              key={router}
              onClick={() => setRouter(router)}
              className="px-2 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {router}
            </button>
          ))}
        </div>
      </div>
      <input
        type="text"
        value={tokenName}
        onChange={(e) => setTokenName(e.target.value)}
        placeholder="Token Name"
        className="w-full px-3 py-2 border rounded-md mb-2"
      />
      <input
        type="text"
        value={tokenSymbol}
        onChange={(e) => setTokenSymbol(e.target.value)}
        placeholder="Token Symbol"
        className="w-full px-3 py-2 border rounded-md mb-2"
      />
      <button
        onClick={deployContract}
        className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
      >
        Deploy Contract
      </button>
      {deployedAddress && (
        <p className="mt-4">
          Contract deployed at: {deployedAddress}
        </p>
      )}
    </div>
  );
}

export default ContractDeployer;