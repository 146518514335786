import React, { useState, useRef } from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

function Auth({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const recaptchaRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const recaptchaToken = recaptchaRef.current.getValue();
    if (!recaptchaToken) {
      setError('Please complete the reCAPTCHA');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/login`, { 
        username, 
        password,
        recaptchaToken
      });
      const { token } = response.data;
      onLogin(token);
    } catch (error) {
      setError(error.response?.data?.error || 'Login failed. Please try again.');
    } finally {
      setLoading(false);
      recaptchaRef.current.reset();
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <h2 className="text-2xl font-bold mb-4">Login</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {error && <p className="text-red-500">{error}</p>}
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            className="w-full p-2 border rounded"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Le4ZSAqAAAAAApCH2Q2mMauYUBL6thcPfUoBaiO"
        />
        <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
        <Link to="/register" className="w-full p-2 bg-green-500 hover:bg-green-600 text-white rounded text-center block mt-4">
          Register
        </Link>
      </form>
    </div>
  );
}

export default Auth;