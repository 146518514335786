import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Modal, Button, Form, Tabs, Tab, Table, Spinner } from 'react-bootstrap';
import BigNumber from 'bignumber.js';
import Switch from 'react-switch';
import { useNavigate } from 'react-router-dom';
import Web3 from 'web3';
import contractABI from '../contractABI.json'; 

const API_URL = process.env.REACT_APP_API_URL;
const WEB3_PROVIDER = process.env.REACT_APP_WEB3_PROVIDER

function AdminDashboard({ user }) {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', password: '', expirationDate: '' });
  const [editUser, setEditUser] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [userLogins, setUserLogins] = useState([]);
  const [web3, setWeb3] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);



  const navigate = useNavigate();
  const token = user.token;



  useEffect(() => {
    const initWeb3 = async () => {
      try {
        const web3Instance = new Web3(new Web3.providers.HttpProvider(WEB3_PROVIDER));
        await web3Instance.eth.net.isListening();
        setWeb3(web3Instance);
      } catch (error) {
        console.error('Failed to connect to Web3:', error);
        Swal.fire('Error', 'Failed to connect to blockchain. Please check your network connection.', 'error');
      }
    };

    initWeb3();
  }, []);

  async function getABI(address) {
    const url = `https://api.bscscan.com/api?module=contract&action=getabi&address=${address}&apikey=4B2KDR31AFGX99K6WIHJRSHQUSYVE9TYZR`;
    const response = await axios.get(url);
    if (response.data.status === '1') {
        // Her istek arasında 3 saniye gecikme ekleyelim
        await new Promise(resolve => setTimeout(resolve, 1000));
        return JSON.parse(response.data.result);
    } else {
        throw new Error('ABI alınamadı');
    }
}


const getLiquidity = useCallback(async (contractAddress, ignoreCache = false) => {
    const cacheKey = `liquidity_${contractAddress}`;
    
    if (!ignoreCache) {
        const cachedData = localStorage.getItem(cacheKey);
        
        if (cachedData) {
            const { liquidity, timestamp } = JSON.parse(cachedData);
            const currentTime = new Date().getTime();

            // Cache süresi 1 saat (3600000 ms)
            if (currentTime - timestamp < 3600000) {
                return liquidity; // Cache'den döndür
            }
        }
    }

    if (!web3) {
        console.error('Web3 is not initialized');
        return 'N/A';
    }

    const WBNB_ADDRESS = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';

    const tokenContract = new web3.eth.Contract(contractABI, contractAddress);

    const routerAddresses = [
        '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', // Uniswap V2
        '0x10ED43C718714eb63d5aA57B78B54704E256024E', // PancakeSwap V2
        '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24'  // Başka bir DEX
    ];

    let router, routerAddress, factoryAddress, pairAddress;

    for (const address of routerAddresses) {
        try {
            const routerABI = await getABI(address);
            const tempRouter = new web3.eth.Contract(routerABI, address);
            
            const tempFactoryAddress = await tempRouter.methods.factory().call();
            
            const factoryABI = await getABI(tempFactoryAddress);
            const factory = new web3.eth.Contract(factoryABI, tempFactoryAddress);
            
            const tempPairAddress = await factory.methods.getPair(contractAddress, WBNB_ADDRESS).call();
            
            if (tempPairAddress !== '0x0000000000000000000000000000000000000000') {
                router = tempRouter;
                routerAddress = address;
                factoryAddress = tempFactoryAddress;
                pairAddress = tempPairAddress;
                break;
            }
        } catch (error) {
            console.log(`${address} için hata:`, error);
        }
    }

    if (!router) {
        console.log('Uyumlu bir DEX bulunamadı.');
        return 'N/A';
    }

    const pairABI = await getABI(pairAddress);
    const pair = new web3.eth.Contract(pairABI, pairAddress);

    const reserves = await pair.methods.getReserves().call();

    const token0 = await pair.methods.token0().call();
    const tokenReserve = token0.toLowerCase() === contractAddress.toLowerCase() ? reserves._reserve0 : reserves._reserve1;
    const bnbReserve = token0.toLowerCase() === contractAddress.toLowerCase() ? reserves._reserve1 : reserves._reserve0;
  
    const decimals = await tokenContract.methods.decimals().call();

    const liquidityValue = new BigNumber(web3.utils.fromWei(bnbReserve, 'ether')).times(2).toString(10) / 2;

    // Cache'e kaydet
    localStorage.setItem(
        cacheKey,
        JSON.stringify({
            liquidity: liquidityValue,
            timestamp: new Date().getTime()
        })
    );

    return liquidityValue;
}, [web3]);



const refreshLiquidity = async () => {
    setRefreshing(true);
    try {
        await fetchContracts(token);

        // Her kontrat için cache'i yok sayarak likiditeyi yeniden çek
        const liquidityPromises = contracts.map(contract => getLiquidity(contract.address, true));
        await Promise.all(liquidityPromises);

        setRefreshKey(prevKey => prevKey + 1);
        Swal.fire('Success', 'Liquidity data refreshed successfully', 'success');
    } catch (error) {
        console.error('Error refreshing liquidity:', error);
        Swal.fire('Error', 'Failed to refresh liquidity data', 'error');
    } finally {
        setRefreshing(false);
    }
};






  useEffect(() => {
    if (token) {
      fetchUsers(token);
      fetchContracts(token);
      fetchPayments(token);
      fetchUserLogins(token);
      const oneMonthLater = new Date();
      oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
      setNewUser((prevState) => ({ ...prevState, expirationDate: formatDate(oneMonthLater) }));
    }
  }, [token]);

  const fetchUsers = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/users`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      Swal.fire('Error', 'Failed to fetch users', 'error');
    }
  };

  const fetchContracts = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/admin-get-contracts`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setContracts(response.data);
    } catch (error) {
      console.error('Error fetching contracts:', error);
      Swal.fire('Error', 'Failed to fetch contracts', 'error');
    }
  };

  const fetchPayments = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/admin-get-payments`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPayments(response.data);
    } catch (error) {
      console.error('Error fetching payments:', error);
      Swal.fire('Error', 'Failed to fetch payments', 'error');
    }
  };

  const fetchUserLogins = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/admin-user-logins`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserLogins(response.data);
    } catch (error) {
      console.error('Error fetching user logins:', error);
      Swal.fire('Error', 'Failed to fetch user logins', 'error');
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    if (!token) {
      Swal.fire('Error', 'No user token is available. Please log in again.', 'error');
      return;
    }
  
    if (!newUser.username || !newUser.password || !newUser.expirationDate) {
      Swal.fire('Error', 'Please fill in all required fields', 'error');
      return;
    }
  
    setIsLoading(true);
  
    try {
      const response = await axios.post(`${API_URL}/users`, {
        username: newUser.username,
        password: newUser.password,
        expirationDate: newUser.expirationDate,
        trialDate: newUser.trialDate
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      if (response.data.success) {
        setNewUser({ 
          username: '', 
          password: '', 
          expirationDate: formatDate(new Date(new Date().setMonth(new Date().getMonth() + 1))),
          trialDate: ''
        });
        setModalIsOpen(false);
        await fetchUsers(token);
        Swal.fire('Success', response.data.message, 'success');
      } else {
        throw new Error(response.data.message || 'Error adding user');
      }
    } catch (error) {
      console.error('Error adding user:', error);
      
      let errorMessage = 'Error adding user';
      if (error.response) {
        if (error.response.status === 403) {
          errorMessage = 'You are not authorized to add users.';
        } else if (error.response.status === 400 && error.response.data.message === 'Username already exists') {
          errorMessage = 'This username is already taken. Please choose another.';
        } else {
          errorMessage = error.response.data.message || error.response.data.error || errorMessage;
        }
      } else if (error.request) {
        errorMessage = 'No response received from server. Please try again.';
      } else {
        errorMessage = error.message;
      }
  
      Swal.fire('Error', errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    if (!token) {
      console.error('No user token is available.');
      return;
    }

    try {
      const { id, username, expiration_date, status, password } = editUser;
      await axios.put(`${API_URL}/users/${id}`, { username, expirationDate: formatDate(expiration_date), status, password }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setEditModalIsOpen(false);
      Swal.fire('Success', 'User updated successfully', 'success');
      fetchUsers(token);
    } catch (error) {
      console.error('Error updating user:', error);
      Swal.fire('Error', 'Error updating user', 'error');
    }
  };

  const handleDeleteUser = async (userId, username) => {
    if (username === 'admin') {
      Swal.fire('Error', 'Admin user cannot be deleted.', 'error');
      return;
    }

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to delete user ${username}. This action cannot be undone!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`${API_URL}/users/${userId}`, {
          headers: { Authorization: `Bearer ${user.token}` }
        });
        Swal.fire('Deleted!', 'User has been deleted.', 'success');
        fetchUsers(user.token);
      } catch (error) {
        console.error('Error deleting user:', error);
        Swal.fire('Error', 'Failed to delete user', 'error');
      }
    }
  };


  const handleEditClick = (user) => {
    setEditUser(user);
    setEditModalIsOpen(true);
  };

  const handleExpirationDateChange = (e) => {
    const newDate = e.target.value;
    setEditUser((prevState) => ({ ...prevState, expiration_date: newDate }));
  };

  const handleStatusChange = (checked) => {
    if (editUser.username === 'admin') {
      Swal.fire('Error', 'Admin user status cannot be changed', 'error');
      return;
    }
    setEditUser((prevState) => ({ ...prevState, status: checked }));
  };

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    return !isNaN(d) ? `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}` : '';
  };

  const handleLoginAsUser = async (userId) => {
    try {
      const response = await axios.post(`${API_URL}/admin-login-as-user`, { userId }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        navigate('/'); // Ana sayfaya yönlendir
      }
    } catch (error) {
      console.error('Error logging in as user:', error);
      Swal.fire('Error', 'Failed to login as user', 'error');
    }
  };

  const handleDeleteUserLogins = async (userId) => {
    try {
      await axios.delete(`${API_URL}/admin-user-logins/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      Swal.fire('Success', 'User logs deleted successfully', 'success');
      fetchUserLogins(token);
    } catch (error) {
      console.error('Error deleting user logs:', error);
      Swal.fire('Error', 'Failed to delete user logs', 'error');
    }
  };

  if (!loading && !user.isAdmin) {
    return <div>Access Denied</div>;
  }

  return (
    <div className="mt-8 bg-white shadow-md rounded-lg p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
          <h1 className="text-3xl sm:text-4xl font-extrabold text-gray-900 mb-4 sm:mb-0">Admin Dashboard</h1>
          <button onClick={() => setModalIsOpen(true)} className="w-full sm:w-auto bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded transition duration-300">
            Add New User
          </button>
        </div>

        <Tabs defaultActiveKey="users" id="admin-dashboard-tabs" className="mb-3">
          <Tab eventKey="users" title="Users">
            <div className="overflow-x-auto bg-white shadow-md rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expired Date</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {users.map((user) => (
                    <tr key={user.id}>
                      <td className="px-6 py-4 whitespace-nowrap">{formatDate(user.created_at)}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.username}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Switch
                          checked={Boolean(user.status)}
                          onChange={() => {}}
                          disabled={user.username === 'admin'}
                          className="react-switch"
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="date"
                          value={formatDate(user.expiration_date)}
                          onChange={() => {}}
                          className="form-control"
                          disabled
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <button onClick={() => handleEditClick(user)} className="bg-yellow-500 hover:bg-yellow-600 text-white py-1 px-3 rounded mr-2 transition duration-300">
                          Edit
                        </button>
                        {user.username !== 'admin' && (
                          <>
                            <button onClick={() => handleDeleteUser(user.id, user.username)} className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded mr-2 transition duration-300">
                              Delete
                            </button>
                            <button onClick={() => handleLoginAsUser(user.id)} className="bg-green-500 hover:bg-green-600 text-white py-1 px-3 rounded transition duration-300">
                              Login as User
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Tab>
          <Tab eventKey="contracts" title="Contracts">
          <div className="mb-4">
            <Button 
              onClick={refreshLiquidity} 
              variant="primary" 
              disabled={refreshing}
            >
              {refreshing ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {' '}Refreshing...
                </>
              ) : (
                'Refresh Liquidity'
              )}
            </Button>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>User ID</th>
                <th>Token Name</th>
                <th>Address</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Liquidity (BNB)</th>
              </tr>
            </thead>
            <tbody>
              {contracts.map(contract => (
                <tr key={contract.id}>
                  <td>{contract.id}</td>
                  <td>{contract.user_id}</td>
                  <td>{contract.token_name}</td>
                  <td>{contract.address}</td>
                  <td>{contract.status ? 'Active' : 'Inactive'}</td>
                  <td>{new Date(contract.created_at).toLocaleString()}</td>
                  <td>
                    {contract.status ? (
                      <LiquidityCell 
                        address={contract.address} 
                        getLiquidity={getLiquidity}
                        refreshKey={refreshKey}
                      />
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
          <Tab eventKey="payments" title="Payments">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>User ID</th>
                  <th>Amount</th>
                  <th>Payment Date</th>
                  <th>Plan Duration</th>
                  <th>Payment Address</th>
                  <th>Network</th>
                  <th>Currency</th>
                  <th>Status</th>
                  <th>Expiration Time</th>
                </tr>
              </thead>
              <tbody>
                {payments.map(payment => (
                  <tr key={payment.id}>
                    <td>{payment.id}</td>
                    <td>{payment.user_id}</td>
                    <td>{payment.amount}</td>
                    <td>{new Date(payment.payment_date).toLocaleString()}</td>
                    <td>{payment.plan_duration} months</td>
                    <td>{payment.payment_address}</td>
                    <td>{payment.payment_network}</td>
                    <td>{payment.payment_currency}</td>
                    <td>{payment.payment_status}</td>
                    <td>{payment.expiration_time ? new Date(payment.expiration_time).toLocaleString() : 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="userLogins" title="User Logins">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>IP</th>
                  <th>Location</th>
                  <th>Browser</th>
                  <th>OS</th>
                  <th>Login Time</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {userLogins.map(log => (
                  <tr key={log.id}>
                    <td>{log.username}</td>
                    <td>{log.user_ip}</td>
                    <td>{`${log.city}, ${log.country} (${log.country_code})`}</td>
                    <td>{log.browser}</td>
                    <td>{log.os}</td>
                    <td>{new Date(log.created_at).toLocaleString()}</td>
                    <td>
                      <Button variant="danger" onClick={() => handleDeleteUserLogins(log.user_id)}>Delete Logs</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Tab>
        </Tabs>

        <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add New User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAddUser} className="space-y-4">
              <Form.Group>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  value={newUser.username}
                  onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={newUser.password}
                  onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Expiration Date</Form.Label>
                <Form.Control
                  type="date"
                  value={newUser.expirationDate}
                  onChange={(e) => setNewUser({ ...newUser, expirationDate: e.target.value })}
                />
              </Form.Group>
              <Button variant="success" type="submit" className="w-100" disabled={isLoading}>
                {isLoading ? 'Adding...' : 'Add User'}    
              </Button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalIsOpen(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={editModalIsOpen} onHide={() => setEditModalIsOpen(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleUpdateUser} className="space-y-4">
              <Form.Group>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  value={editUser?.username}
                  onChange={(e) => setEditUser({ ...editUser, username: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  value={editUser?.password}
                  onChange={(e) => setEditUser({ ...editUser, password: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Expiration Date</Form.Label>
                <Form.Control
                  type="date"
                  value={formatDate(editUser?.expiration_date)}
                  onChange={handleExpirationDateChange}
                />
              </Form.Group>
              <Form.Group className="flex items-center">
                <Switch
                  checked={Boolean(editUser?.status)}
                  onChange={handleStatusChange}
                  disabled={editUser?.username === 'admin'}
                />
                <Form.Label className="ml-2">Active</Form.Label>
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">
                Save Changes
              </Button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setEditModalIsOpen(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

function LiquidityCell({ address, getLiquidity, refreshKey }) {
    const [liquidity, setLiquidity] = useState('Loading...');
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      let isMounted = true;
      setLoading(true);
      const fetchLiquidity = async () => {
        try {
          const result = await getLiquidity(address);
          if (isMounted) {
            if (result === 'N/A' || result === 'Error') {
              setLiquidity(result);
            } else {
              const liquidityNumber = parseFloat(result);
              setLiquidity(liquidityNumber.toFixed(8) + ' BNB');
            }
          }
        } catch (error) {
          console.error('Error in LiquidityCell:', error);
          if (isMounted) {
            setLiquidity('Error');
          }
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      };
      fetchLiquidity();
      return () => { isMounted = false; };
    }, [address, getLiquidity, refreshKey]);
  
    if (loading) {
      return <Spinner animation="border" size="sm" />;
    }
  
    return <span>{liquidity}</span>;
  }
  
  
  
export default AdminDashboard;